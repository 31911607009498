import axios, { AxiosResponse } from 'axios';
import { apiRoutes } from '../rest/APIRoutes';

export const postUnsubscribe = async (requestObj: Record<string, any>): Promise<AxiosResponse> => {
    try {
        const response = await axios.post(apiRoutes.unsubscribeURL,
            requestObj,
            {
                headers:
                    { "Content-Type": 'application/json' }
            });
        return response;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
}