export const configuration = () => {

    const APP_ENV = process.env.REACT_APP_ENV || 'local';
    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    if (REACT_APP_API_BASE_URL != null && REACT_APP_API_BASE_URL.trim().length > 0) {
        return { baseURL: REACT_APP_API_BASE_URL.trim() };
    }
    const localConfiguration = {
        baseURL: 'http://localhost:8080'
    }
    const devConfiguration = {
        baseURL: 'https://email-web-unsubscribe-api.dev.hcpcomms.com'
    }
    const qaConfiguration = {
        baseURL: 'https://email-web-unsubscribe-api.qa.hcpcomms.com'
    }
    const stgConfiguration = {
        baseURL: 'https://email-web-unsubscribe-api.stg.hcpcomms.com'
    }
    const prodConfiguration = {
        baseURL: 'https://email-web-unsubscribe-api.hcpcomms.com'
    }
    if (APP_ENV === 'local') {
        return localConfiguration;
    } else if (APP_ENV === 'dev') {
        return devConfiguration;
    } else if (APP_ENV === 'qa') {
        return qaConfiguration;
    } else if (APP_ENV === 'stg') {
        return stgConfiguration;
    } else if (APP_ENV === 'prod') {
        return prodConfiguration;
    }
}
