import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routeElements } from './navigation/Routes';
import React from "react";

function App() {

  const routeElement = routeElements;

  return (
    <BrowserRouter>
      <Routes>
        {routeElement.map((route, index) => (
          <Route key={index} caseSensitive path={route.path} element={<route.element/>} />
        ))}
      </Routes>
    </BrowserRouter>
  );

}

export default App;

