import Unsubscribe from '../Pages/Unsubscribe';

export const routes = {
    main: '/',
    unsubscribe: '/unsubscribe',
    optout: '/optout',
}

export const routeElements = [
    { path: routes.unsubscribe, element: Unsubscribe },
    { path: routes.optout, element: Unsubscribe },
];