import { useEffect, useState } from 'react'
import messageBundle from '../assets/resource_bundle/messages.json';
import '../../src/stylesheet.css';
import { routes } from '../navigation/Routes';
import { URL_PATH, genericUnsubscribeSearchParamKeys, optoutSearchParamKeys } from '../constants/constants';
import { postUnsubscribe } from '../api/postUnsubscribe';
import React from 'react';


const Unsubscribe = () => {

    const url = new URL(window.location.href);

    useEffect(() => {
        const isApiTriggered = sessionStorage.getItem("isApiTriggered") === "true";
        if (!isApiTriggered) {
            // If the API hasn't been triggered, set the flag and call handleUnsubscribe()
            sessionStorage.setItem("isApiTriggered", "true");
            handleUnsubscribe();
        }
    }, []);

    const getParamsKeysArray = (pathname: string): string[] => {
        if (pathname === routes.optout) {
            return optoutSearchParamKeys;
        }
        return genericUnsubscribeSearchParamKeys;
    }

    const removeTrailingSlashes = (pathname: string): string => {
        try {
            return pathname.replace(/\/+$/, '');
        } catch (error) {
            console.log("Error in removeTrailingSlashes");
            return pathname.replace(/\/+$/, '');
        }
    }

    function getQueryStringParameters(url: string) {
        try {
            // Check if the URL contains a query string
            const queryStringIndex = url.indexOf('?');
            if (queryStringIndex !== -1) {
                // Extract the query string from the URL
                const queryString = url.slice(queryStringIndex + 1);
                // Split the query string into individual key-value pairs
                const pairs = queryString.split(/&(?=[^=&]+=)/);
                // Create an object to store the parameters
                const parameters: any = {};
                // Loop through each key-value pair and add it to the parameters object
                pairs.forEach(pair => {
                    const [key, value] = pair.split('=');
                    try {
                        parameters[key] = decodeURIComponent(value);
                    } catch (error) {
                        console.log('Manual decode for key: ', key);
                        // Replace %20 with a single space
                        let formattedValue = value.replace(/%20/g, ' ');
                        parameters[key] = formattedValue;
                    }

                });
                return parameters;
            }
            // If there is no query string, return an empty object
            return {};
        } catch (error) {
            console.log("Error in getQueryStringParameters ", error);
        }

    }

    const handleUnsubscribe = async () => {
        try {
            // Split the path into segments and get the last segment
            let segments = url.pathname.split('/').filter(segment => segment); // Remove empty segments
            let lastSegment = segments[segments.length - 1];

            const requestObj: Record<string, string> = {};

            //decode url
            const decodedURL = new URL(decodeURIComponent(url.href));

            // Get correct query parameter keys
            const pathname = removeTrailingSlashes(url.pathname);
            const paramKeys = getParamsKeysArray(pathname);
            const queryStringObj = getQueryStringParameters(decodedURL.href)

            // Loop through the keys and get the values or use an empty string if they are null or undefined
            paramKeys.forEach(key => {
                const value = queryStringObj[key] || '';
                requestObj[key] = value;
            });

            //Add urlPath to the requestObj
            requestObj[URL_PATH] = lastSegment;

            await postUnsubscribe(requestObj);

        } catch (error) {
            console.log('Error in handleClick', error);
        }
    };

    return (
        <div className='main-container'>
            <div className='inner-container'>
                <div className="ack-unsubscribed-container">
                    <p className="confirm-text">{messageBundle.acknowledgement}</p>
                </div>
            </div>
        </div>
    );
}


export default Unsubscribe
